import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './vuex/store'
import axios from 'axios'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as VueGoogleMaps from "vue2-google-maps"


import Loader from './views/includes/Loader';
import MiniLoader from './views/includes/MiniLoader';
Vue.component('loader', Loader);
Vue.component('mini-loader', MiniLoader);

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(CoreuiVue);
// Vue.prototype.$log = console.log.bind(console)
Vue.use(axios);
Vue.use(Antd);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB6Am1NwNbXrw-Axo3Ehv1oIfTdKdcXFMY",
    libraries: "places"
  }
});

axios.defaults.baseURL = 'https://express.eastline.uz/api/client';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;

axios.get('/user').then((response) =>  {
  if(response.status == 200){
      localStorage.auth = true;
      localStorage.me = JSON.stringify(response.data);
  }
}).catch((error) => {
  if(error.response.status == 401){
    localStorage.auth = false;
    this.$router.push('/pages/login');
  }
})


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  beforeMount(){

  },
  mounted(){

  }
})
