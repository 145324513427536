import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

import mutations from './mutations/mutations'
import actions from './actions/actions'

const state = {
  // sidebarShow: 'responsive',
  token: localStorage.token || null,
  auth: localStorage.auth,
  sidebarShow: false,
  mapToken: 'AIzaSyB6Am1NwNbXrw-Axo3Ehv1oIfTdKdcXFMY',
  sidebarMinimize: false,
  paginateCount: 20,
  urlBackend: 'https://express.eastline.uz',
  noPhoto: 'https://express.eastline.uz/images/static/nophoto.jpg',
  emptyUser: 'https://express.eastline.uz/images/static/empty_user.jpg',
  emptyClient: 'https://express.eastline.uz/images/clients/static/nophoto.jpg',
  volumeWeight: 5000
}

const getters = {
  isAuthenticated: state => !!state.auth,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
